import React from 'react';
import { TextBoxStyled, Heading, TextStyled } from '../design-system';

const TextBox = ({ heading, children, as, color, headingSize, ...props }) => {
  return (
    <TextBoxStyled {...props}>
      <Heading fontSize={headingSize} as={as} color={color}>
        {heading}
      </Heading>
      <TextStyled>{children}</TextStyled>
    </TextBoxStyled>
  );
};

export default TextBox;
