import { detect } from 'detect-browser';
import Fingerprint2 from 'fingerprintjs2';

const captureDeviceHash = async () => {
  await Fingerprint2.get(components => {
    const hash = Fingerprint2.x64hash128(components.map(component => component.value).join(''), 31);
    return hash;
  });
};

const captureDeviceData = async () => {
  const deviceHash = await captureDeviceHash();
  const appVersion = process.env.REACT_APP_VERSION;
  const { version, os, name } = detect();
  return {
    platform: 'web',
    appVersion,
    browser: name,
    browserVersion: version,
    operatingSystem: os,
    ...deviceHash,
  };
};

export default captureDeviceData;
