import React from 'react';
import { Query } from 'react-apollo';
import { Link } from '@reach/router';
import { loader } from 'graphql.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomer } from '../../../member-hub/src/context/userContext';

const ALL_NOTIFICATIONS_QUERY = loader('../../../member-hub/src/graphql/queries/allNotificationsQuery.graphql');

const Notifications = ({ hamburgerActive, hamburgerClick, setHamburgerActive, currentUser }) => {
  const customer = useCustomer();
  return (
    <div className='notifications'>
      <Query
        query={ALL_NOTIFICATIONS_QUERY}
        fetchPolicy='network-only'
        variables={{ getUserNotificationsInput: { limit: 10, offset: 0, customerId: customer.customerId } }}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data }) => {
          const unreadNotifications = !loading && !error ? data.getUserNotifications.unreadCount : 0;
          console.log(unreadNotifications);
          return (
            <Link
              className={unreadNotifications > 0 ? 'unread' : ''}
              onClick={() => {
                setHamburgerActive(false);
              }}
              to='/notifications'
            >
              <FontAwesomeIcon icon='envelope' />
            </Link>
          );
        }}
      </Query>
      <button
        type='button'
        className={hamburgerActive ? 'hamburger hamburger--squeeze is-active' : 'hamburger hamburger--squeeze'}
        onClick={e => hamburgerClick(e)}
      >
        <div className='hamburger-box'>
          <div className='hamburger-inner' />
        </div>
      </button>
    </div>
  );
};

export default Notifications;
