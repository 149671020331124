import React, { useState, useEffect } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import Select from './Select';
import { Box, Grid, StyledLabel } from '../design-system';

dayjs.extend(customParseFormat);

const months = [
  {
    id: 0,
    label: 'January',
    value: 1,
  },
  {
    id: 1,
    label: 'February',
    value: 2,
  },
  {
    id: 2,
    label: 'March',
    value: 3,
  },
  {
    id: 3,
    label: 'April',
    value: 4,
  },
  {
    id: 4,
    label: 'May',
    value: 5,
  },
  {
    id: 5,
    label: 'June',
    value: 6,
  },
  {
    id: 6,
    label: 'July',
    value: 7,
  },
  {
    id: 7,
    label: 'August',
    value: 8,
  },
  {
    id: 8,
    label: 'September',
    value: 9,
  },
  {
    id: 9,
    label: 'October',
    value: 10,
  },
  {
    id: 10,
    label: 'November',
    value: 11,
  },
  {
    id: 11,
    label: 'December',
    value: 12,
  },
];

export const getYearRange = (start, end) => {
  const step = -1;
  return Array.from({ length: (end - start) / step + 1 }, (_, i) => {
    const year = start + i * step;
    return { id: i, label: year, value: year };
  });
};

const currYear = new Date().getFullYear();
const years = getYearRange(currYear, currYear - 100, 1, 'descending');

const MonthYearSelect = ({ label, handleChange, customRender, setValue, address }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const month = address && address.selectedMonth ? months.find(option => option.value === address.selectedMonth) : null;
  const year = address && address.selectedYear ? years.find(option => option.value === address.selectedYear) : null;

  useEffect(() => {
    const today = dayjs();
    let selectedDate = null;

    if (selectedMonth && selectedYear) {
      selectedDate = dayjs(`${selectedMonth} ${selectedYear}`, 'M YYYY');
    } else if (address && address.selectedMonth && address.selectedYear) {
      selectedDate = dayjs(`${address.selectedMonth} ${address.selectedYear}`, 'M YYYY');
    }
    const monthsFromToday = today.diff(selectedDate, 'month');

    if (selectedDate) {
      handleChange(monthsFromToday);
      if (customRender) {
        customRender();
      }
    }
  }, [customRender, address, handleChange, selectedMonth, selectedYear]);

  return (
    <Box>
      {label && (
        <StyledLabel htmlFor={label}>
          <span>{label}</span>
        </StyledLabel>
      )}
      <Grid gridGap={2} gridTemplateColumns={[null, null, 'repeat(2, 1fr)']}>
        <Box>
          <Select
            options={months}
            onChange={item => {
              setSelectedMonth(item.value);
              setValue('selectedMonth', item.value);
            }}
            value={month}
            placeholder='Select month'
            required
            scrollable
          />
        </Box>
        <Box>
          <Select
            options={years}
            onChange={item => {
              setSelectedYear(item.value);
              setValue('selectedYear', item.value);
            }}
            value={year}
            placeholder='Select year'
            required
            scrollable
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default MonthYearSelect;
