import React from 'react';
import styled from '@emotion/styled';
import Icon from './Icon';
import { DynamicComponet, Heading, TextStyled } from '../design-system';

const Styles = styled(DynamicComponet)`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 50px 1fr;
  grid-template-areas:
    'heading heading'
    'left right';
  h3 {
    grid-area: heading;
  }
  .icon {
    grid-area: left;
  }
  .text {
    grid-area: right;
  }
`;

const TitleCard = ({ color, iconBackground, iconSize, icon, heading, as, text }) => {
  return (
    <Styles>
      <Heading as={as} color={color}>
        {heading}
      </Heading>
      <Icon className='icon' icon={icon} bg={iconBackground} size={iconSize} />
      <TextStyled className='text' color={color} fontSize={[1, 2]}>
        {text}
      </TextStyled>
    </Styles>
  );
};

export default TitleCard;
