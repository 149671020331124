import React, { useEffect, useState } from 'react';
import { Stepper as _Stepper } from 'react-form-stepper';

export const Stepper = ({ steps: _steps, activeStep, theme }) => {
  const [steps, setSteps] = useState(_steps);

  useEffect(() => {
    setSteps(_steps);
  }, [_steps]);

  return (
    <>
      <_Stepper
        steps={[...steps, { label: 'Done' }]}
        activeStep={activeStep}
        styleConfig={{
          activeBgColor: theme.colors.primary,
          activeTextColor: '#FFFFFF',
          inactiveBgColor: '#FFFFFF',
          inactiveTextColor: theme.colors.primary,
          completedBgColor: '#FFFFFF',
          completedTextColor: theme.colors.primary,
          size: '2.5em',
          labelFontSize: '1.2em',
        }}
      />
    </>
  );
};
