import React, { createContext, useContext } from 'react';
import { useAuth } from './authContext';

const UserContext = createContext();

const UserProvider = props => {
  const {
    data: { user, customer, communicationPreferences },
    setCustomer,
  } = useAuth();
  return (
    <UserContext.Provider
      value={{
        user,
        customer,
        communicationPreferences,
        setCustomer,
      }}
      {...props}
    />
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context.user; // Only return the user part of the context
};

const useCustomer = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useCustomer must be used within a UserProvider`);
  }
  return context.customer; // Only return the customer part of the context
};

const useCommunicationPreferences = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useCommunicationPreferences must be used within a UserProvider`);
  }
  return context.communicationPreferences; // Only return the communicationPreferences part of the context
};

const useSetCustomer = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`setCustomer must be used within a UserProvider`);
  }
  return context.setCustomer; // Only return the setCustomer part of the context
};

export { UserProvider, useUser, useCustomer, useCommunicationPreferences, useSetCustomer };
