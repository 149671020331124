import React from 'react';
import '../css/throbber.scss';

const Throbber = () => {
  return (
    <div className='throbber-container' aria-label='loading'>
      <div className='sk-folding-cube'>
        <div className='sk-cube1 sk-cube' />
        <div className='sk-cube2 sk-cube' />
        <div className='sk-cube4 sk-cube' />
        <div className='sk-cube3 sk-cube' />
      </div>
    </div>
  );
};

export default Throbber;
