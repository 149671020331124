import React from 'react';
import { useTheme } from 'emotion-theming';
import { Link } from '@reach/router';

const Banner = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        border: `1px solid ${theme.colors.mediumGrey}`,
        padding: '5px',
        paddingTop: '3px',
        paddingBottom: '3px',
        borderRadius: '4px',
        marginTop: '10px',
        marginBottom: '10px',
        textAlign: 'center',
        boxSizing: 'border-box',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <p style={{ fontSize: '12px' }}>
        Customer portal is currently under development and therefore there may be some anomalies. Please flag it{' '}
        <Link style={{ color: theme.colors.primary, textDecoration: 'none', fontSize: '14px' }} to='/contact'>
          HERE
        </Link>{' '}
        if you find any. Please note that settlement figures need to be confirmed by CWRT therefore give us a call.
      </p>
    </div>
  );
};

export default Banner;
