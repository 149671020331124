import React from 'react';
import { Link } from '@reach/router';

const Nav = ({ setActiveMenu, setHamburgerActive, activeMenu, creditUnion }) => {
  let menuItems = [
    { label: 'My Accounts', to: '/dashboard', section: 'dashboard' },
    (() => (!creditUnion.hasLoans ? {} : { label: 'Apply for a Loan', to: 'products/loans', section: 'products' }))(),
    (() => (creditUnion?.hideNews ? {} : { label: 'News', to: '/news', section: 'news' }))(),
    (() => (creditUnion?.hideHelpAndInfo ? {} : { label: 'Help & Info', to: '/help' }))(),
    { label: 'Documents', to: '/documents' },
    { label: 'Contact Us', to: '/contact' },
  ];
  if (creditUnion.hasBeneficiary) {
    menuItems.splice(2, 0, { label: 'Payees', to: '/payees', section: 'payees' });
  }

  return (
    <nav className={activeMenu ? 'menu-items is-active' : 'menu-items'}>
      <ul>
        {menuItems.map(item => {
          if (!item.label) return;
          return (
            <li key={item.label}>
              <Link
                onClick={() => {
                  setActiveMenu(false);
                  setHamburgerActive(false);
                }}
                to={item.to}
                getProps={({ isCurrent }) => {
                  return {
                    className: isCurrent ? 'active' : null,
                  };
                }}
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
