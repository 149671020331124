import { getCommunicationPreferences, getCustomer, getUser } from './authClient';

export const userBootstrapAppData = async () => {
  const user = await getUser();
  if (!user || !user.currentUser) {
    return { user: null };
  }

  const { currentUser } = user;
  if (!currentUser.linkedParties || currentUser.linkedParties.length === 0) {
    return { user: currentUser };
  }
  const { customer, communicationPreferences } = await customerBootstrapAppData(user.currentUser);

  return { user: currentUser, customer, communicationPreferences };
};

export const customerBootstrapAppData = async (user, customerId) => {
  const initialCustomerId = customerId ? customerId : user?.linkedParties[0].customerId;
  const customer = await getCustomer(initialCustomerId);

  if (!customer || !customer.currentCustomer) {
    return { customer: null };
  }
  const { currentCustomer } = customer;

  const communicationPreferences = await getCommunicationPreferences(user.individualDetails.individualId, currentCustomer.customerId);

  return { customer: currentCustomer, communicationPreferences };
};
