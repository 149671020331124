import { loader } from 'graphql.macro';
import Auth from '@aws-amplify/auth';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getMobileNumber } from '../helpers/functions';
import captureDeviceData from '../helpers/captureDeviceData';
import client from './apiClient';

const CURRENT_USER_QUERY = loader('../graphql/queries/currentUserQuery.graphql');
const CURRENT_CUSTOMER_QUERY = loader('../graphql/queries/currentCustomer.graphql');
const COMMUNICATION_PREFERENCES_MUTATION = loader('../graphql/mutations/setCommunicationPreferencesMutation.graphql');
const VALIDATE_DETAILS_MUTATION = loader('../graphql/mutations/validateDetailsMutation.graphql');
const VALIDATE_SECURITY_MUTATION = loader('../graphql/mutations/validateSecurityMutation.graphql');
const UPDATE_DETAILS_MUTATION = loader('../graphql/mutations/updateDetailsMutation.graphql');
const SET_PIN_MUTATION = loader('../graphql/mutations/setPinMutation.graphql');
const PRE_AUTH_MUTATION = loader('../graphql/mutations/preAuthCheckMutation.graphql');
const ACCEPT_REGISTRATION_TERMS = loader('../graphql/mutations/acceptRegistrationTermsMutation.graphql');
const GET_CUSTOMER_PAYEES = loader('../graphql/queries/getCustomerPayees.graphql');
const GET_COMMUNICATION_PREFERENCES = loader('../graphql/queries/getCommunicationPreferences.graphql');

const getUser = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (!currentUser) {
      return null;
    }
    const { data, loading, error } = await client.query({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'network-only',
    });
    if (!loading && !error) return data;
    return null;
  } catch (error) {
    return null;
  }
};

const getCustomer = async customerId => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (!currentUser) {
      return null;
    }
    const { data, loading, error } = await client.query({
      query: CURRENT_CUSTOMER_QUERY,
      variables: {
        currentCustomerInput: {
          customerId,
        },
      },
      fetchPolicy: 'network-only',
    });
    if (!loading && !error) {
      // I want to query the bank accounts and append it to data

      try {
        const { data: payeeData } = await client.query({
          query: GET_CUSTOMER_PAYEES,
          fetchPolicy: 'network-only',
          variables: {
            getCustomerPayeesInput: {
              customerId,
            },
          },
        });
        const payees = payeeData?.getCustomerPayees?.payees || [];

        return {
          currentCustomer: {
            ...data.currentCustomer,
            bankAccounts: payees,
          },
        };
      } catch (error) {
        console.log({ error });
      }

      return {
        currentCustomer: data.currentCustomer,
      };
    }
    return null;
  } catch (error) {
    return null;
  }
};

const getCommunicationPreferences = async (individualId, customerId) => {
  try {
    const { data } = await client.query({
      query: GET_COMMUNICATION_PREFERENCES,
      variables: {
        getCommunicationPreferencesInput: {
          individualId,
          customerId,
        },
      },
      fetchPolicy: 'network-only',
    });
    return data.getCommunicationPreferences;
  } catch (error) {
    return null;
  }
};

const login = async ({ email, password, pin }) => {
  try {
    const newLogin = await Auth.signIn(email, password);
    const webDevice = await captureDeviceData();
    const {
      data: {
        preAuthCheck: { success },
      },
    } = await client.mutate({
      mutation: PRE_AUTH_MUTATION,
      variables: {
        pin,
        webDevice,
      },
    });
    if (success) return newLogin;
    return await Auth.signOut();
  } catch (err) {
    await Auth.signOut();
    throw err;
  }
};

const register = async ({ soarUserId, email, password, pin, niNumber, dateOfBirth, mobileNumber, isRegistered, requireDobOnRegister }) => {
  dayjs.extend(customParseFormat);
  const attributes = {
    'custom:soarUserId': soarUserId,
    'custom:niNumber': niNumber,
  };
  if (requireDobOnRegister) {
    const birthdate = dayjs(dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD');
    attributes['birthdate'] = birthdate;
  }
  let phoneNumber;
  if (mobileNumber) {
    const { number } = await getMobileNumber(mobileNumber);
    phoneNumber = number || '';
    attributes.phone_number = phoneNumber;
  }
  if (!isRegistered) {
    await Auth.signUp({
      username: email,
      password,
      attributes,
    });
  }

  await client.mutate({
    mutation: SET_PIN_MUTATION,
    variables: {
      pin,
      email,
    },
  });
};

const validateDetails = async ({ email, memberNumber, mobileNumber, dateOfBirth, niNumber, accountNumber, currentBalanceValue }) => {
  const { data } = await client.mutate({
    mutation: VALIDATE_DETAILS_MUTATION,
    variables: {
      email,
      memberNumber,
      mobileNumber,
      dateOfBirth,
      niNumber,
      accountNumber,
      currentBalanceValue,
    },
  });
  return data;
};

const acceptTerms = async ({ customerId, partyId, soarUserId, userAgreements }) => {
  const { data } = await client.mutate({
    mutation: ACCEPT_REGISTRATION_TERMS,
    variables: { updatedAcceptRegistrationTermsAndConditionsInput: { customerId, partyId, soarUserId, userAgreements } },
  });

  return data;
};

const validateSecurityQuestions = async ({ memberNumber, accountNumber, postCode, sortCode, regularContributions, regularContributionMethod }) => {
  const { data } = await client.mutate({
    mutation: VALIDATE_SECURITY_MUTATION,
    variables: {
      memberNumber,
      accountNumber,
      postCode,
      sortCode,
      regularContributions,
      regularContributionMethod,
    },
  });
  return data;
};

const updateDetails = async ({ email, mobileNumber }) => {
  const { data } = await client.mutate({
    mutation: UPDATE_DETAILS_MUTATION,
    variables: {
      email,
      mobileNumber,
    },
  });
  return data;
};

const completeRegistration = async ({ communicationPreferences, email, password }) => {
  await Auth.signIn(email, password);
  const webDevice = await captureDeviceData();
  await client.mutate({
    mutation: COMMUNICATION_PREFERENCES_MUTATION,
    variables: {
      setCommunicationPreferences: {
        communicationPreferences,
        webDevice,
      },
    },
  });
  await Auth.signOut();
};

const resetCredentials = async ({ email }) => {
  await Auth.forgotPassword(email);
};

const resetCredentialsSubmit = async ({ email, authCode, password, pin, updatePinOnly }) => {
  if (!updatePinOnly) {
    await Auth.forgotPasswordSubmit(email, authCode, password);
  }
  if (pin) {
    const { data } = await client.mutate({
      mutation: SET_PIN_MUTATION,
      variables: {
        pin,
        email,
      },
    });
    return data;
  }
  return true;
};

const logout = async () => {
  try {
    return await Auth.signOut();
  } catch (error) {
    return error;
  }
};
export {
  login,
  register,
  logout,
  getUser,
  getCustomer,
  getCommunicationPreferences,
  completeRegistration,
  validateDetails,
  resetCredentials,
  resetCredentialsSubmit,
  validateSecurityQuestions,
  updateDetails,
  acceptTerms,
};
