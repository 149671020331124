import React from 'react';
import styled from '@emotion/styled/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme, TextStyled } from '../design-system';

const TooltipIcon = styled.div`
  background: transparent;
  color: ${props => props.iconColor};
  width: 40px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
`;

const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: -180px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 230px;
  padding: 5px 5px;
  border-radius: 4px;

  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s, padding 0.5s ease-in-out;

  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 190px;
    top: -10px;
    position: absolute;

    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.5s ease-in-out;
  }

  p {
    margin: 5px;
  }
`;
const TooltipCard = styled.div`
  position: relative;
  & ${TooltipIcon}:hover + ${TooltipBox} {
    visibility: visible;
    background-color: ${props => props.bg};
    z-index: 1;
    width: 230px;
    padding: 10px 10px;

    &:before {
      border-color: transparent transparent ${props => props.bg} ${props => props.bg};
    }
  }
`;

const ToolTip = ({ infoText, textColor, iconColor }) => {
  return (
    <>
      <TooltipCard bg={iconColor}>
        <TooltipIcon iconColor={theme.colors.midGray}>
          <FontAwesomeIcon icon='info-circle' />
        </TooltipIcon>
        <TooltipBox>
          <TextStyled color={textColor} fontSize={1}>
            {infoText}
          </TextStyled>
        </TooltipBox>
      </TooltipCard>
    </>
  );
};

export default ToolTip;
