import React, { lazy, useEffect, Suspense } from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { ThemeProvider } from 'emotion-theming';
import { Global, css } from '@emotion/core';
import { Themes } from '@soar/component-library';
import { useCustomer, useUser } from './context/userContext';
import Throbber from './library/Throbber';

const ModeratExtendedBoldOtf = require('./fonts/Moderat/Moderat-Extended-Bold.otf');
const MyriadProRegularOtf = require('./fonts/Myriad/MyriadPro-Regular.otf');
const MyriadProLightOtf = require('./fonts/Myriad/MyriadPro-Light.otf');

config.autoAddCss = false;
const theme = Themes[process.env.REACT_APP_THEME];

const loadAuthenticatedApp = () => import('./authenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./unauthenticatedApp'));
const MaintenanceView = lazy(() => import('./screens/MaintenanceScreen'));

const App = () => {
  const user = useUser();
  const customer = useCustomer();

  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  let screenToLoad = user && customer ? <AuthenticatedApp /> : <UnauthenticatedApp />;
  if (process.env.REACT_APP_MAINTENANCE === 'true') screenToLoad = <MaintenanceView />;
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Throbber />}>
        <main>
          <Global
            styles={css`
              @font-face {
                font-family: 'Moderat';
                src: url(${ModeratExtendedBoldOtf}) format('opentype');
              }
              @font-face {
                font-family: 'MyriadPro';
                src: url(${MyriadProRegularOtf}) format('opentype');
              }
              @font-face {
                font-family: 'Myriad';
                src: url(${MyriadProLightOtf}) format('opentype');
              }
              * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
              }
              body {
                font-family: ${theme.fonts.bodyFonts};
                color: ${theme.colors.textColor};
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                line-height: 1.2;
                overflow-x: hidden;
              }
              #root,
              main {
                display: flex;
                flex-direction: column;
                flex: 1;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              .title {
                font-family: ${theme.fonts.headingFonts};
                font-weight: ${theme.fontWeights.headingFonts};
              }
              p {
                color: ${theme.colors.textColor};
              }
            `}
          />
          {screenToLoad}
        </main>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
