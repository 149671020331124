/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCog, faFileAlt, faSignOutAlt, faTimes, faUserCircle, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import { useSetCustomer, useCustomer, useUser } from '../../../member-hub/src/context/userContext';
import Select from 'react-select';

const ProfileMenu = ({ logout, showMenu, toggleSideMenu, userMenuActive, activeMenu, creditUnion, memberNamingConvention, isLegalEntity }) => {
  const currentUser = useUser();
  const toggleCustomerContext = useSetCustomer();
  const currentCustomer = useCustomer();
  const options = currentUser.linkedParties.map(linkedParty => ({
    label: `${linkedParty.partyId} - ${linkedParty.partyName}`,
    value: linkedParty.customerId,
  }));
  const individualDetails = currentUser.individualDetails;

  return (
    <div className={activeMenu ? 'user-menu is-active' : 'user-menu'}>
      <ul>
        <li onClick={e => userMenuActive(e)} className={showMenu ? 'side-menu is-active' : 'side-menu'}>
          <div className='upper'>
            <FontAwesomeIcon icon={faUserCircle} />
          </div>
          <p className='user-name'>
            {individualDetails.fullName}
            {(() => {
              if (creditUnion.useAccountNumber) {
                return (
                  <span>
                    Account number:
                    <em>{currentCustomer.partyId}</em>
                  </span>
                );
              } else if (!creditUnion.useAccountNumber && isLegalEntity) {
                return (
                  <span>
                    Reference number:
                    <em>{currentCustomer.partyId}</em>
                  </span>
                );
              } else if (!creditUnion.useAccountNumber && memberNamingConvention) {
                return (
                  <span>
                    {memberNamingConvention.charAt(0).toUpperCase() + memberNamingConvention.slice(1)} number:
                    <em>{currentCustomer.partyId}</em>
                  </span>
                );
              }
            })()}
          </p>
          <FontAwesomeIcon icon={faCaretDown} />
          <ul className='inner-menu'>
            <FontAwesomeIcon icon={faTimes} onClick={toggleSideMenu} />
            <div className='top'>
              <div className='right'>
                <h3 className='user-name'>{individualDetails.fullName}</h3>
                {(() => {
                  if (creditUnion.useAccountNumber) {
                    return (
                      <span>
                        Account number:
                        <em>{currentCustomer.partyId}</em>
                      </span>
                    );
                  } else if (memberNamingConvention) {
                    return (
                      <span>
                        {memberNamingConvention.charAt(0).toUpperCase() + memberNamingConvention.slice(1)} number:
                        <em>{currentCustomer.partyId}</em>
                      </span>
                    );
                  }
                })()}
              </div>
            </div>
            {currentUser.linkedParties && currentUser.linkedParties.length > 1 && (
              <li>
                <Select
                  label='Select Account'
                  value={{ label: `${currentCustomer.partyId} - ${currentCustomer.partyName}` }}
                  onChange={({ value }) => toggleCustomerContext(value)}
                  options={options}
                  className='select'
                  classNamePrefix='select'
                />
              </li>
            )}

            <li>
              <Link onClick={toggleSideMenu} to='/settings'>
                <FontAwesomeIcon icon={faCog} />
                Profile Settings
              </Link>
            </li>
            <li>
              <Link onClick={toggleSideMenu} to='/termsandconditions'>
                <FontAwesomeIcon icon={faFileSignature} />
                Terms and conditions
              </Link>
            </li>
            <li>
              <Link onClick={toggleSideMenu} to='/documents'>
                <FontAwesomeIcon icon={faFileAlt} />
                Documents
              </Link>
            </li>
            <li>
              <button
                type='submit'
                className='logout-button'
                onClick={() => {
                  toggleSideMenu();
                  logout();
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
                Log out
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ProfileMenu;
