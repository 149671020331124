import React from 'react';
import styled from '@emotion/styled/macro';

const Tab = styled.button`
  font-weight: bold;
  padding: 0 35px;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans', sans-serif;
  color: ${props => props.theme.colors.white};
  transition: background 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
  height: 40px;
  margin-top: 5px;
  background: #4c2a65;
  border-radius: 0;
  outline: 0;
  &:last-of-type {
    border-top-right-radius: 5px;
  }
  &:first-of-type {
    border-top-left-radius: 5px;
  }
  &:hover {
    background: #5d3f74;
  }
  ${({ active }) =>
    active &&
    `
    height: 45px;
    margin-top: 0;
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primary};
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    &:hover {
    background: ${props => props.theme.colors.white};
  }
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
  position: absolute;
  bottom: -50px;
  left: ${props => props.left};
`;

const TabGroup = ({ tabs, activeTab, setActiveTab, left }) => {
  return (
    <>
      <ButtonGroup left={left || 0}>
        {tabs.map((tab, i) => (
          <Tab key={tab.name} active={activeTab === i} onClick={() => setActiveTab(i)}>
            {tab.name}
          </Tab>
        ))}
      </ButtonGroup>
    </>
  );
};

export default TabGroup;
