import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { AuthProvider } from './authContext';
import { UserProvider } from './userContext';

import client from '../utils/apiClient';

const AppProviders = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default AppProviders;
