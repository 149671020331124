import React from 'react';
import { StyledButton, Box, Flex } from '../design-system';
import ButtonSpinner from './ButtonSpinner';

const DefaultButton = ({ children, text, onClick, above = false, after, loading, ...props }) => {
  return (
    <StyledButton onClick={!loading ? onClick : null} {...props}>
      {!loading && (
        <>
          {after && (
            <>
              {text}
              <Box ml={2}>{children}</Box>
            </>
          )}
          {above && (
            <Flex flexDirection='column'>
              <Box mb='5px'>{children}</Box>
              {text}
            </Flex>
          )}
          {!after && !above && (
            <>
              <Box mr={2}>{children}</Box>
              {text}
            </>
          )}
        </>
      )}
      {loading && <ButtonSpinner />}
    </StyledButton>
  );
};

export default DefaultButton;
