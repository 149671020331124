import React from 'react';
import ReactDOM from 'react-dom';
import { rgba } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Flex, theme, Box } from '../design-system';
import { useTheme } from 'emotion-theming';

const Modal = ({ show, setShowModal, role, children }) => {
  const theme = useTheme();
  return ReactDOM.createPortal(
    <Flex
      as='section'
      position='absolute'
      top={0}
      left={0}
      width='100%'
      height='100%'
      justifyContent='center'
      alignItems='center'
      bg={`${rgba(theme.colors.secondary, 0.9)}`}
      opacity={show ? 1 : 0}
      p={0}
      overflow-y='scroll'
      role={role}
      tabIndex='-1'
    >
      <Box width={['100%', null, '80%', '590px']} bg='#f7f7f7' position='relative' padding='40px 30px' borderRadius='5px'>
        <Flex flexDirection='column'>
          <FontAwesomeIcon
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              width: '1.5em',
              height: '1.5em',
              cursor: 'pointer',
            }}
            icon='times'
            onClick={() => {
              setShowModal(false);
            }}
          />
          {children}
        </Flex>
      </Box>
    </Flex>,
    document.body,
  );
};

export default Modal;
