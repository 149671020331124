import { parsePhoneNumberFromString } from 'libphonenumber-js';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js';

export const formatCurrency = (amount, denomination, decimal = 2) => {
  // amount is NaN if a null/undefined/'' value is passed to a parser
  // meaning the user didn't provide the value, so return £0.00 instead
  if (Number.isNaN(Number(amount)) || !amount) return '£0.00';
  const sign = amount < 0 ? '-' : '';
  let _amount = null;

  switch (denomination) {
    case 'pounds':
      _amount = amount.toFixed(decimal);
      break;
    case 'pence':
    default:
      _amount = (amount / 100).toFixed(decimal);
      break;
  }

  const formatted = _amount
    .toString()
    .replace('-', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${sign}£${formatted}`;
};

export const flattenForm = ({ sections }) => {
  const flattenedForm = {};
  let navigationOrder = 1;

  sections.sort((a, b) => (a.sectionNumber > b.sectionNumber ? 1 : -1));

  sections.forEach(section => {
    const { steps } = section;

    steps.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));

    steps.forEach(step => {
      flattenedForm[step.key] = {
        ...step,
        sectionKey: section.key,
        sectionTitle: section.title,
        navigationOptions: { title: section.title, inStack: step.backButtonMode === 'previous' },
        navigationOrder,
      };

      navigationOrder += 1;
    });
  });

  return flattenedForm;
};

export const getFieldValues = (field, dataObject) => {
  const { parentKey, key, requiresField } = field;
  const fieldParent = parentKey ? dataObject[parentKey] : null;
  const fieldValue = fieldParent ? fieldParent[key] : dataObject[key];
  const requiredFieldValue = requiresField ? dataObject[requiresField.fieldKey] : null;
  return { fieldParent, fieldValue, requiredFieldValue };
};

export const isValidMobileNumber = phoneNumber => {
  if (phoneNumber == null) return false;
  const number = parsePhoneNumberFromString(phoneNumber, 'GB');
  if (number === undefined) return false;
  return (number?.isValid() && number.getType() === 'MOBILE') ?? false;
};

export const isValidHomeNumber = phoneNumber => {
  if (phoneNumber == null) return false;
  const number = parsePhoneNumberFromString(phoneNumber, 'GB');
  if (number === undefined) return false;
  return number?.isValid() ?? false;
};

export const isValidEmail = email => {
  if (!email) return false;
  const regex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$');
  const validInput = regex.test(email);
  return validInput;
};

export const isValidNiNumber = niNumber => {
  if (!niNumber) return false;
  const regex = new RegExp('[a-zA-Z]{2}[0-9]{6}[A-Za-z]{1}');
  const validInput = regex.test(niNumber);
  return validInput;
};

export const isOverMinJoiningAge = (minimumJoiningAge, dateOfBirth) => {
  const minAge = dayjs().subtract(minimumJoiningAge, 'years');
  const dob = dayjs(dateOfBirth, 'DD-MM-YYYY').toISOString();
  const isValidAge = dayjs(dob).isBefore(minAge);
  return isValidAge;
};

export const calculatePirFromApr = (apr, periodType) => {
  switch (periodType) {
    case 'MONTHLY': {
      // PIR = (1+APR)**(1/12)-1
      const aprDecimal = apr / 100;
      const tempApr = 1 + aprDecimal;
      const pir = new Decimal(tempApr).pow(Decimal('1').div(Decimal('12'))).sub(Decimal('1'));
      return parseFloat(pir);
    }
    case 'FOURWEEKLY': {
      // PIR = (1+APR)**(1/(365/28))-1
      const aprDecimal = apr / 100;
      const tempApr = 1 + aprDecimal;
      const pir = new Decimal(tempApr).pow(Decimal('1').div(Decimal('365').div(Decimal('28')))).sub(Decimal('1'));
      return parseFloat(pir);
    }
    case 'FORTNIGHTLY': {
      // PIR = (1+APR)**(1/(365/14))-1
      const aprDecimal = apr / 100;
      const tempApr = 1 + aprDecimal;
      const pir = new Decimal(tempApr).pow(Decimal('1').div(Decimal('365').div(Decimal('14')))).sub(Decimal('1'));
      return parseFloat(pir);
    }
    case 'WEEKLY': {
      // PIR = (1+APR)**(1/(365/7))-1
      const aprDecimal = apr / 100;
      const tempApr = 1 + aprDecimal;
      const pir = new Decimal(tempApr).pow(Decimal('1').div(Decimal('365').div(Decimal('7')))).sub(Decimal('1'));
      return parseFloat(pir);
    }
  }
};

export const calculatePeriodicPayment = (apr = null, loanAmount, loanTerm, mir = null, periodType) => {
  const calcPir = calculatePirFromApr(apr, periodType);

  const interestLoanTermScale =
    calcPir == 0
      ? loanTerm //handle APR being zero
      : 1 / calcPir - 1 / (calcPir * (calcPir + 1) ** loanTerm);
  // for loans, round monthly payment up to nearest pence
  return parseInt(new Decimal(loanAmount).div(interestLoanTermScale).toString());
};

export const isFieldRendered = (field, dataObject) => {
  const { requiredFieldValue } = getFieldValues(field, dataObject);
  const { requiresField } = field;

  if (requiresField) {
    const result = Array.isArray(requiresField.fieldValue);
    if (!result && requiredFieldValue !== requiresField.fieldValue) return false;
    if (result && requiresField.fieldValue.length >= 1 && !requiresField.fieldValue.find(element => element === requiredFieldValue)) return false;
  }

  return true;
};

export const getStepFields = (step, formData) => {
  const { fields, dynamicFields } = step;

  if (dynamicFields) {
    const { dynamicSetKey, fieldSets } = dynamicFields;
    const dynamicFieldValue = formData[dynamicSetKey];
    return fieldSets[dynamicFieldValue];
  }

  return fields;
};

export const roundToNearestMultiple = (input, multiple) => {
  // Using floats (especially between 0 and 1) might cause this function to misbehave
  // Source: http://phrogz.net/round-to-nearest-via-modulus-division
  if (input % multiple === 0) return input;
  return input + multiple / 2 - ((input + multiple / 2) % multiple);
};

export const penceFormat = amount => {
  return parseInt(Math.round(parseFloat(amount) * 100));
};

export const goalSeek = ({ fn, fnParams, percentTolerance, customToleranceFn, maxIterations, maxStep, goal, independentVariableIdx }) => {
  if (typeof customToleranceFn !== 'function') {
    if (!percentTolerance) {
      throw Error('invalid inputs');
    }
  }
  let g;
  let y;
  let y1;
  let oldGuess;
  let newGuess;
  let res;

  // iterate through the guesses
  for (let i = 0; i < maxIterations; i++) {
    // define the root of the function as the error
    res = fn.apply(null, fnParams);
    y = res - goal;
    if (isNaN(y)) throw Error('invalid inputs');
    // was our initial guess a good one?
    if (customToleranceFn(y)) return fnParams[independentVariableIdx];
    // set the new guess, correcting for maxStep
    oldGuess = fnParams[independentVariableIdx];
    newGuess = oldGuess + y;
    if (Math.abs(newGuess - oldGuess) > maxStep) {
      if (newGuess > oldGuess) {
        newGuess = oldGuess + maxStep;
      } else {
        newGuess = oldGuess - maxStep;
      }
    }
    fnParams[independentVariableIdx] = newGuess;
    // re-run the fn with the new guess
    y1 = fn.apply(null, fnParams) - goal;
    if (isNaN(y1)) throw Error('NAN');
    // calculate the error
    g = (y1 - y) / y;
    if (g === 0) g = 0.0001;
    // set the new guess based on the error, correcting for maxStep
    newGuess = oldGuess - y / g;
    if (maxStep && Math.abs(newGuess - oldGuess) > maxStep) {
      if (newGuess > oldGuess) {
        newGuess = oldGuess + maxStep;
      } else {
        newGuess = oldGuess - maxStep;
      }
    }
    fnParams[independentVariableIdx] = newGuess;
  }
  throw Error('failed to converge');
};


export default {
  flattenForm,
  formatCurrency,
  getFieldValues,
  isValidMobileNumber,
  isValidHomeNumber,
  isValidEmail,
  isValidNiNumber,
  calculatePirFromApr,
  calculatePeriodicPayment,
  isFieldRendered,
  roundToNearestMultiple,
  penceFormat,
  goalSeek,
};
